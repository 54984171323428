<template>
  <div>
    <div class="box" v-drag>
      <div class="close-button" @click="onCancel">
        <i class="el-icon-circle-close"></i>
      </div>
      <div>轨 迹 融 合 组 件</div>
      
      <div class="line">
        <div class="lable">融合轨迹</div>
        <legend-line styleFlag="line" circleColor="#fa1" lineColor="#165184" />
        <el-switch class="switch"
          v-model="mixValue"
          active-color="#8bb035"
          inactive-color="#666666"
          @change="mixChange"
        >
        </el-switch>
      </div>

      <div class="line">
        <div class="lable"> AIS 轨迹</div>
        <legend-line styleFlag="line" circleColor="lightgreen" lineColor="#FF0011" />
        <el-switch class="switch"
          :disabled="mixValue"
          v-model="aisValue"
          active-color="#8bb035"
          inactive-color="#666666"
          @change="aisChange"
        >
        </el-switch>
      </div>
      
      <div class="line">
        <div class="lable">开源轨迹</div>

        <legend-line  styleFlag="line" circleColor="#ffff00" lineColor="#16919a" />

        <el-switch class="switch"
          :disabled="mixValue"
          v-model="opsValue"
          active-color="#8bb035"
          inactive-color="#666666"
          @change="opsChange" 
        >
        </el-switch>
      </div>

            
    </div>
  </div>
</template>

<script>
import vDrag from "../utils/drag.js";
import LegendLine from "./legendLine.vue";
export default {
  components: {
    LegendLine,
  },
  // props: ["mixValue1", "aisValue1", "opsValue1"],
  data() {
    return {
      mixValue: true,
      aisValue: false,
      opsValue: false
    };
  },
  computed: {
    getLineStyle() {
      return {
        "margin-left": "-2px",
        "background-image": `linear-gradient(to right, ${this.lineColor} 50%, rgba(255,255,255,0) 0%)` /* 50%设置虚线点x轴上的长度 */,
      };
    },
    getCircleStyle() {
      return {
        background: this.circleColor,
      };
    },
  },
  methods: {
    onCancel() {
      this.$emit("legendVueCancel")
    },
    mixChange(newValue) {
      if(newValue) {
        this.opsValue = false;
        this.aisValue = false;
      }else {
        this.opsValue = true;
        this.aisValue = true;
      }
      this.$emit('mixChange', newValue)
    },
    opsChange(newValue) {
      this.$emit('opsChange', newValue)
    },
    aisChange(newValue) {
      this.$emit('aisChange', newValue)
    }
  },
  mounted() {
    this.mixChange(true); 
  },
  directives: {
    drag: vDrag,
  },
};
</script>

<style lang="less" scoped>
.box {
  position: absolute;
  top: 52px;
  right: 443px;
  width: 260px;
  height: 200px;
  padding: 20px;
  text-align: center;
  color: aliceblue;
  font-size: 17px;
  background-color: rgba(0, 0, 0, 0.56);
  z-index: 99999;
    box-shadow: -3px 0 3px -1px gray, 0 -2px 3px -1px gray, 0 2px 3px -1px gray,
          2px 0 3px -1px gray;
    // box-shadow: 8px 8px 5px #888888;
    border-radius: 10px;

  .lable{
    font-size: 13px;
    margin-right: 20px;
  }

  .line {
    margin-top: 30px;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .switch {
      margin-left: 30px;
    }
  }

  .close-button {
    position: absolute;
    top: 3px;
    right: 5px;
    z-index: 9277;
    font-size: 25px;
    color: #fff;
  }

}
</style>
